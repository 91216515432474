import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step ssh login`}</strong>{` -- adds a SSH certificate into the authentication agent`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step ssh login [<identity>]
[--token=<token>] [--provisioner=<name>] [--provisioner-password-file=<file>]
[--principal=<string>] [--not-before=<time|duration>] [--not-after=<time|duration>]
[--set=<key=value>] [--set-file=<file>] [--force]
[--offline] [--ca-config=<file>]
[--ca-url=<uri>] [--root=<file>] [--context=<name>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step ssh login`}</strong>{` generates a new SSH key pair and send a request to `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/certificates"
      }}>{`step
certificates`}</a>{` to sign a user
certificate. This certificate will be automatically added to the SSH agent.`}</p>
    <p>{`With a certificate servers may trust only the CA key and verify its signature on
a certificate rather than trusting many user keys.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`identity`}</inlineCode>{`
The certificate identity. If no principals are passed we will use
the identity as a principal, if it has the format abc@def then the
principal will be abc.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--token`}</strong>{`=`}<inlineCode parentName="p">{`token`}</inlineCode>{`
The one-time `}<inlineCode parentName="p">{`token`}</inlineCode>{` used to authenticate with the CA in order to create the
certificate.`}</p>
    <p><strong parentName="p">{`--add-user`}</strong>{`
Create a user provisioner certificate used to create a new user.`}</p>
    <p><strong parentName="p">{`--principal`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`, `}<strong parentName="p">{`-n`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
Add the specified principal (username) to the certificate request.
This flag can be used multiple times. However, it cannot be used in conjunction
with '--token' when requesting certificates from OIDC, JWK, and X5C provisioners, or
from any provisioner with 'disableCustomSANs' set to 'true'. These provisioners will
use the contents of the token to determine the principals.`}</p>
    <p><strong parentName="p">{`--identity`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
The certificate identity. It is usually passed as a positional argument, but a
flag exists so it can be configured in $STEPPATH/config/defaults.json.`}</p>
    <p><strong parentName="p">{`--provisioner`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`, `}<strong parentName="p">{`--issuer`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The provisioner `}<inlineCode parentName="p">{`name`}</inlineCode>{` to use.`}</p>
    <p><strong parentName="p">{`--provisioner-password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`, `}<strong parentName="p">{`--password-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the `}<inlineCode parentName="p">{`file`}</inlineCode>{` containing the password to decrypt the one-time token
generating key.`}</p>
    <p><strong parentName="p">{`--not-before`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` when the certificate validity period starts. If a `}<inlineCode parentName="p">{`time`}</inlineCode>{` is
used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is used, it is a
sequence of decimal numbers, each with optional fraction and a unit suffix, such
as "300ms", "-1.5h" or "2h45m". Valid time units are "ns", "us" (or "µs"), "ms",
"s", "m", "h".`}</p>
    <p><strong parentName="p">{`--not-after`}</strong>{`=`}<inlineCode parentName="p">{`time|duration`}</inlineCode>{`
The `}<inlineCode parentName="p">{`time|duration`}</inlineCode>{` when the certificate validity period ends. If a `}<inlineCode parentName="p">{`time`}</inlineCode>{` is
used it is expected to be in RFC 3339 format. If a `}<inlineCode parentName="p">{`duration`}</inlineCode>{` is used, it is a
sequence of decimal numbers, each with optional fraction and a unit suffix, such
as "300ms", "-1.5h" or "2h45m". Valid time units are "ns", "us" (or "µs"), "ms",
"s", "m", "h".`}</p>
    <p><strong parentName="p">{`--set`}</strong>{`=`}<inlineCode parentName="p">{`key=value`}</inlineCode>{`
The `}<inlineCode parentName="p">{`key=value`}</inlineCode>{` pair with template data variables. Use the `}<strong parentName="p">{`--set`}</strong>{` flag multiple times to add multiple variables.`}</p>
    <p><strong parentName="p">{`--set-file`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The JSON `}<inlineCode parentName="p">{`file`}</inlineCode>{` with the template data variables.`}</p>
    <p><strong parentName="p">{`-f`}</strong>{`, `}<strong parentName="p">{`--force`}</strong>{`
Force the overwrite of files without asking.`}</p>
    <p><strong parentName="p">{`--offline`}</strong>{`
Creates a certificate without contacting the certificate authority. Offline mode
uses the configuration, certificates, and keys created with `}<strong parentName="p">{`step ca init`}</strong>{`,
but can accept a different configuration file using `}<strong parentName="p">{`--ca-config`}</strong>{` flag.`}</p>
    <p><strong parentName="p">{`--ca-config`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The certificate authority configuration `}<inlineCode parentName="p">{`file`}</inlineCode>{`. Defaults to
$(step path)/config/ca.json`}</p>
    <p><strong parentName="p">{`--ca-url`}</strong>{`=`}<inlineCode parentName="p">{`URI`}</inlineCode>{`
`}<inlineCode parentName="p">{`URI`}</inlineCode>{` of the targeted Step Certificate Authority.`}</p>
    <p><strong parentName="p">{`--root`}</strong>{`=`}<inlineCode parentName="p">{`file`}</inlineCode>{`
The path to the PEM `}<inlineCode parentName="p">{`file`}</inlineCode>{` used as the root certificate authority.`}</p>
    <p><strong parentName="p">{`--context`}</strong>{`=`}<inlineCode parentName="p">{`name`}</inlineCode>{`
The context `}<inlineCode parentName="p">{`name`}</inlineCode>{` to apply for the given command.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Request a new SSH certificate and add it to the agent:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh login bob
`}</code></pre>
    <p>{`Request a new SSH certificate using an OIDC provisioner:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh login
`}</code></pre>
    <p>{`Request a new SSH certificate valid only for 1h:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh login --not-after 1h alice
`}</code></pre>
    <p>{`Request a new SSH certificate with multiple principals:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step ssh login --principal admin --principal bob bob@smallstep.com
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      